import React from 'react';

const Footer = () => {

    return (
        <footer className="container-fluid bg-dark text-light text-center py-5">
            <p>Newbie Coder | <small>Learn with Fun</small></p>
        </footer>
    );
};

export default Footer;