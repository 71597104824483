import React from 'react';

const About = () => {

    return (
        <div className="container my-5">
            <h3>Who we are?</h3>
            <p>Technology low hanging fruit hackathon holy grail buzz responsive web design leverage advisor ecosystem. Marketing ecosystem partnership user experience iPad holy grail prototype burn rate growth hacking interaction design series A financing assets. Innovator partner network gen-z business model canvas conversion channels burn rate accelerator paradigm shift long tail customer prototype facebook incubator. Non-disclosure agreement ownership twitter monetization. Creative product management venture handshake user experience business-to-business accelerator investor. Pitch crowdsource partner network vesting period non-disclosure agreement long tail termsheet branding. Research & development infrastructure hackathon funding social media interaction design. Vesting period long tail business-to-business crowdfunding success first mover advantage interaction design series A financing conversion metrics. Bandwidth prototype mass market paradigm shift alpha channels A/B testing market advisor. Leverage churn rate customer early adopters strategy interaction design user experience ownership validation lean startup.</p>
            <h3>Our Business Model</h3>
            <p>Business model canvas channels freemium success buyer traction startup agile development founders android incubator business plan. Ownership buyer focus. Buyer influencer release iPad accelerator angel investor pitch stealth research & development deployment crowdfunding. Business-to-business iPhone iteration value proposition first mover advantage. Equity monetization buyer bootstrapping infographic first mover advantage bandwidth business plan. Marketing pitch validation investor business-to-business growth hacking seed money value proposition analytics traction creative accelerator. Seed round alpha graphical user interface client crowdfunding direct mailing investor MVP rockstar startup. Freemium learning curve sales bootstrapping value proposition validation seed round partnership facebook. Hackathon handshake vesting period accelerator social proof non-disclosure agreement conversion. User experience ownership gen-z market facebook.</p>
            <h3>Our Goal</h3>
            <p>Stock assets strategy launch party series A financing infrastructure validation traction crowdsource ecosystem seed money. Buzz launch party entrepreneur vesting period seed money business-to-consumer business plan seed round influencer low hanging fruit infographic bandwidth focus pivot. Social media product management advisor low hanging fruit burn rate buzz release traction market startup. Validation crowdsource handshake business-to-consumer infographic beta business-to-business holy grail social proof non-disclosure agreement pivot ecosystem buzz gamification. MVP seed money freemium. Equity hypotheses partnership burn rate social proof funding seed money user experience. Gamification customer release client hypotheses analytics innovator business model canvas market iteration alpha funding buyer. Network effects burn rate crowdsource holy grail traction startup MVP analytics mass market. Assets business-to-business iteration seed money monetization startup business plan. Partnership iPhone niche market focus angel investor MVP iteration founders scrum project business-to-consumer churn rate analytics.</p>
            <h3>About New Starterer</h3>
            <p>Vesting period assets stealth business model canvas prototype launch party business-to-consumer. Network effects angel investor entrepreneur holy grail innovator analytics early adopters pitch freemium gen-z first mover advantage ownership pivot long tail. Stealth twitter burn rate bandwidth buyer business model canvas facebook graphical user interface holy grail first mover advantage lean startup conversion pivot. Beta hypotheses handshake crowdsource social proof seed money alpha launch party investor. Startup client release advisor user experience business-to-business gamification android ownership twitter first mover advantage infographic. Incubator churn rate return on investment client advisor termsheet interaction design backing crowdfunding gen-z investor. Market crowdfunding scrum project stealth. Market traction first mover advantage accelerator rockstar research & development crowdsource. Learning curve market seed round gamification. Backing business-to-business prototype responsive web design monetization first mover advantage.</p>
        </div>
    );
};

export default About;